import React, { useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Input } from "../../components/shared/InputField";
import {
  Box,
  HStack,
  VStack,
  Error,
  ButtonPrimary,
  ButtonPrimary1,
  isEsopViewer,
} from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  ListOfPlansWithoutTemplate,
  PlanLevelGrantTemplate,
  SiteSettingsRes,
  GrantLetterTemplate,
} from "../../types/SiteSettings";
import convertToBase64 from "../../utils/convertToBase64";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import Tooltip from "../../components/shared/Tooltip";
import { SiteSettingsContext } from "./SiteSettings";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;

  fileType?: string;
}

function GrantLetterSettings() {
  const formik = useFormikContext<SiteSettingsRes>();
  const isUserEsopViewer = isEsopViewer();
  const [grantLetter, setGrantLetter] = useState(
    formik.values.grantLetterTemplates
  );
  const isError = useContext(SiteSettingsContext);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;

  const grantLetterSettingsErrors = errors.acceptanceNumber;
  function doPartialTouch() {
    formik.setFieldTouched("acceptanceNumber");
  }
  function handleSubmit() {
    formik.handleSubmit();
  }
  const [imageDialog, setImageDialog] = useState<{
    open: boolean;
  }>({ open: false });

  const isValidImageFileUploaded = (
    file: File,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
    if (file.size / 1024 > 50) {
      e.target.files = null;
      e.target.value = "";
      toast("File size should be less than 50kb", {
        type: "error",
      });
      return false;
    }
    return true;
  };

  const handleShowDialog = () => {
    setImageDialog({ open: true });
  };

  async function downloadGrantLetterTemplate(
    planLevelGrantTemplate: GrantLetterTemplate
  ) {
    const blob = await convertBase64ToBlob(
      planLevelGrantTemplate.planLevelGrantTemplate,
      "application/msword"
    );
    downloadBlobObject(
      blob,
      `${planLevelGrantTemplate.templateId} - Grant Letter`
    );
  }

  async function downloadGrantLetterWithoutTemplate(
    planLevelGrantTemplate: ListOfPlansWithoutTemplate
  ) {
    const blob = await convertBase64ToBlob(
      planLevelGrantTemplate.planLevelGrantTemplate || "",
      "application/msword"
    );
    downloadBlobObject(
      blob,
      `${planLevelGrantTemplate.templateId} - Grant Letter`
    );
  }

  async function downloadGrantLetter(
    planLevelGrantTemplate: PlanLevelGrantTemplate
  ) {
    const blob = await convertBase64ToBlob(
      planLevelGrantTemplate.planLevelGrantTemplate,
      "application/msword"
    );
    downloadBlobObject(
      blob,
      `${planLevelGrantTemplate.templateId} - Grant Letter`
    );
  }

  return (
    <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg overflow-auto">
      <Box className="max-h-[550px] overflow-auto">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Grant Letter Settings
          </Box>
          <HStack className="gap-4">
            <p className={` form-label text-sm font-medium mt-4`}>
              Window for acceptance of Grant letter (in days)
            </p>
            <Input
              type="number"
              className="lg:w-48 md:w-32"
              {...formik.getFieldProps("acceptanceNumber")}
            />
          </HStack>
          <HStack className="justify-between">
            <HStack
              className={` form-label text-sm font-medium w-2/3 gap-4 items-center`}
            >
              Request Employees to Sign the Grant Letter{" "}
              <Tooltip
                text={`If Enabled, please make sure that Grant Letter Template has the placeholders for Employee Signature`}
                _className="w-[350px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </HStack>
            <Box className="flex items-start w-1/3 gap-1 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "employeeSignatureForGrant",
                    !formik.values.employeeSignatureForGrant
                  );
                }}
                className=""
                value={formik.values.employeeSignatureForGrant}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <HStack
              className={` form-label text-sm font-medium w-2/3 gap-4 items-center`}
            >
              Request Signature while approving a Grant
              <Tooltip
                text={`If Enabled, the approver would have to sign while approving the grant`}
                _className="w-[350px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </HStack>
            <Box className="flex items-start w-1/3 gap-1 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "requestApproverSignature",
                    !formik.values.requestApproverSignature
                  );
                }}
                className=""
                value={formik.values.requestApproverSignature}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <HStack
              className={` form-label text-sm font-medium w-2/3 gap-4 items-center`}
            >
              Prepone Vesting by 1 day
              <Tooltip
                text={`If Enabled, the vestings will get preponed by 1 day`}
                _className="w-[200px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </HStack>
            <Box className="flex items-start w-1/3 gap-1 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "preponeVesting",
                    !formik.values.preponeVesting
                  );
                }}
                className=""
                value={formik.values.preponeVesting || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium  `}>
              Override default Grant letter template
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "isCustomizedPlanEnabled",
                    !formik.values.isCustomizedPlanEnabled
                  );
                }}
                className=""
                value={formik.values.isCustomizedPlanEnabled}
                label=""
              />
            </Box>
          </HStack>
          {formik.values.isCustomizedPlanEnabled && (
            <Box className="max-h-full overflow-auto h-[300px]">
              <table className="w-full table-space">
                <thead className="text-xs font-medium text-[#B5B5C3] ">
                  <tr className=" border-b border-[#E4E6EF] border-dashed">
                    <td className="py-3 hover:cursor-pointer">
                      <HStack className="ml-6">
                        PLAN<VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        GRANT LETTER
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        UPLOAD
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        DELETE
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                  </tr>
                </thead>
                <tbody className="font-medium text-dark">
                  {formik.values.grantLetterTemplates?.map((_, index) => (
                    <tr
                      key={index}
                      className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                    >
                      <td className="py-2">
                        <VStack>
                          <Box>
                            <Input
                              id={index.toString()}
                              type="text"
                              className="w-36"
                              disabled
                              {...formik.getFieldProps(
                                `grantLetterTemplates[${index}].planName`
                              )}
                            />
                          </Box>
                        </VStack>
                      </td>
                      <td className="py-2">
                        <Box className="w-32">
                          {formik.values.grantLetterTemplates[index]
                            .planLevelGrantTemplate !== null &&
                            formik.values.grantLetterTemplates[index]
                              .planLevelGrantTemplate !== "" && (
                              <div className="relative flex flex-col justify-between">
                                <div
                                  className="mt-4 text-red-500 underline"
                                  onClick={() =>
                                    downloadGrantLetterTemplate(
                                      formik.values.grantLetterTemplates[index]
                                    )
                                  }
                                >
                                  Grant Letter
                                </div>
                              </div>
                            )}
                        </Box>
                      </td>
                      <td className="py-2">
                        <Box className="w-8 mt-4">
                          <label className="px-2 py-2 rounded-lg cursor-pointer ">
                            <Input
                              type="file"
                              className="hidden"
                              accept=".docx"
                              id={index.toString()}
                              onChange={async (e) => {
                                if (e?.target?.files?.[0]) {
                                  const file = e.target.files[0];
                                  const fileExtenstion =
                                    file.name.split(".")[
                                      file.name.split(".").length - 1
                                    ];
                                  const acceptedExtensions = ["docx"];
                                  if (
                                    acceptedExtensions.includes(
                                      fileExtenstion.toLowerCase()
                                    )
                                  ) {
                                    const base64: string =
                                      (await convertToBase64(file)) as string;
                                    formik.setFieldValue(
                                      `grantLetterTemplates[${index}].planLevelGrantTemplate`,
                                      base64
                                    );
                                    formik.setFieldValue(
                                      `grantLetterTemplates[${index}].isChanged`,
                                      true
                                    );
                                  } else {
                                    toast("File type not supported", {
                                      type: "error",
                                    });
                                  }
                                }
                              }}
                            />
                            <Icon icon="ic:baseline-file-upload" height={24} />
                          </label>
                        </Box>
                      </td>
                      <td className="py-2">
                        <Box className="w-8 mt-4 ml-2">
                          <div
                            onClick={() => {
                              if (
                                formik.values.grantLetterTemplates[index]
                                  .planLevelGrantTemplate !== null &&
                                formik.values.grantLetterTemplates[index]
                                  .planLevelGrantTemplate !== ""
                              ) {
                                formik.setFieldValue(
                                  `grantLetterTemplates[${index}].planLevelGrantTemplate`,
                                  null
                                );
                                formik.setFieldValue(
                                  `grantLetterTemplates[${index}].isChanged`,
                                  true
                                );
                              }
                            }}
                            className="text-zinc-300 hover:scale-105"
                          >
                            <Icon
                              icon="mi:delete"
                              color="#ff0000"
                              height={24}
                            />
                          </div>
                        </Box>
                      </td>
                    </tr>
                  ))}
                  {formik.values.planLevelGrantTemplate?.map((_, index) => (
                    <tr
                      key={index}
                      className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                    >
                      <td className="py-2">
                        <VStack>
                          <Box>
                            <Input
                              type="text"
                              className="w-36"
                              disabled
                              {...formik.getFieldProps(
                                `planLevelGrantTemplate[${index}].planName`
                              )}
                            />
                          </Box>
                        </VStack>
                      </td>
                      <td className="py-2">
                        <Box className="w-32">
                          {formik.values.planLevelGrantTemplate[index]
                            .planLevelGrantTemplate !== null &&
                            formik.values.planLevelGrantTemplate[index]
                              .planLevelGrantTemplate !== "" && (
                              <div className="relative flex flex-col justify-between">
                                <div
                                  className="mt-4 text-red-500 underline"
                                  onClick={() =>
                                    downloadGrantLetter(
                                      formik.values.planLevelGrantTemplate[
                                        index
                                      ]
                                    )
                                  }
                                >
                                  Grant Letter
                                </div>
                              </div>
                            )}
                        </Box>
                      </td>
                      <td className="py-2">
                        <Box className="w-8 mt-4 ml-3">
                          <label className="px-2 py-2 rounded-lg cursor-pointer ">
                            <Input
                              type="file"
                              className="hidden"
                              accept=".docx"
                              id={index.toString()}
                              onChange={async (e) => {
                                if (e?.target?.files?.[0]) {
                                  const file = e.target.files[0];
                                  const fileExtenstion =
                                    file.name.split(".")[
                                      file.name.split(".").length - 1
                                    ];
                                  const acceptedExtensions = ["docx"];
                                  if (
                                    acceptedExtensions.includes(
                                      fileExtenstion.toLowerCase()
                                    )
                                  ) {
                                    const base64: string =
                                      (await convertToBase64(file)) as string;
                                    formik.setFieldValue(
                                      `planLevelGrantTemplate[${index}].planLevelGrantTemplate`,
                                      base64
                                    );
                                    formik.setFieldTouched(
                                      `planLevelGrantTemplate[${index}].planLevelGrantTemplate`
                                    );
                                  } else {
                                    toast("File type not supported", {
                                      type: "error",
                                    });
                                  }
                                }
                              }}
                            />
                            <Icon icon="ic:baseline-file-upload" height={24} />
                          </label>
                        </Box>
                      </td>
                      <td className="py-2">
                        <Box className="w-8 mt-4 ml-2">
                          {`${formik.values.planLevelGrantTemplate[index].planLevelGrantTemplate}` && (
                            <div
                              onClick={() => {
                                formik.setFieldValue(
                                  `planLevelGrantTemplate[${index}].planLevelGrantTemplate`,
                                  ""
                                );
                              }}
                              className="text-zinc-300 hover:scale-105"
                            >
                              <Icon
                                icon="mi:delete"
                                color="#ff0000"
                                height={24}
                              />
                            </div>
                          )}
                        </Box>
                      </td>
                    </tr>
                  ))}
                  {formik.values.listOfPlansWithoutTemplates?.map(
                    (_, index) => (
                      <tr
                        key={index}
                        className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                      >
                        <td className="py-2">
                          <VStack>
                            <Box>
                              <Input
                                id={index.toString()}
                                type="text"
                                className="w-36"
                                disabled
                                {...formik.getFieldProps(
                                  `listOfPlansWithoutTemplates[${index}].planName`
                                )}
                              />
                            </Box>
                          </VStack>
                        </td>
                        <td className="py-2">
                          <Box className="w-32">
                            {formik.values.listOfPlansWithoutTemplates[index]
                              .planLevelGrantTemplate !== null &&
                              formik.values.listOfPlansWithoutTemplates[index]
                                .planLevelGrantTemplate !== "" && (
                                <div className="relative flex flex-col justify-between">
                                  <div
                                    className="mt-4 text-red-500 underline"
                                    onClick={() =>
                                      downloadGrantLetterWithoutTemplate(
                                        formik.values
                                          .listOfPlansWithoutTemplates[index]
                                      )
                                    }
                                  >
                                    Grant Letter
                                  </div>
                                </div>
                              )}
                          </Box>
                        </td>
                        <td className="py-2">
                          <Box className="w-8 mt-4">
                            <label className="px-2 py-2 rounded-lg cursor-pointer ">
                              <Input
                                type="file"
                                className="hidden"
                                accept=".docx"
                                id={index.toString()}
                                onChange={async (e) => {
                                  if (e?.target?.files?.[0]) {
                                    const file = e.target.files[0];
                                    const fileExtenstion =
                                      file.name.split(".")[
                                        file.name.split(".").length - 1
                                      ];
                                    const acceptedExtensions = ["docx"];
                                    if (
                                      acceptedExtensions.includes(
                                        fileExtenstion.toLowerCase()
                                      )
                                    ) {
                                      const base64: string =
                                        (await convertToBase64(file)) as string;
                                      formik.setFieldValue(
                                        `listOfPlansWithoutTemplates[${index}].planLevelGrantTemplate`,
                                        base64
                                      );
                                      formik.setFieldTouched(
                                        `listOfPlansWithoutTemplates[${index}].planLevelGrantTemplate`
                                      );
                                    } else {
                                      toast("File type not supported", {
                                        type: "error",
                                      });
                                    }
                                  }
                                }}
                              />
                              <Icon
                                icon="ic:baseline-file-upload"
                                height={24}
                              />
                            </label>
                          </Box>
                        </td>
                        <td className="py-2">
                          <Box className="w-8 mt-4 ml-2">
                            {`${formik.values.listOfPlansWithoutTemplates[index].planLevelGrantTemplate}` && (
                              <div
                                onClick={() => {
                                  formik.setFieldValue(
                                    `listOfPlansWithoutTemplates[${index}].planLevelGrantTemplate`,
                                    ""
                                  );
                                }}
                                className="text-zinc-300 hover:scale-105"
                              >
                                <Icon
                                  icon="mi:delete"
                                  color="#ff0000"
                                  height={24}
                                />
                              </div>
                            )}
                          </Box>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Box>
          )}
          <HStack className="flex items-center gap-6">
            <p className={` form-label text-sm font-medium mt-4`}>
              Authorised signature to be used on Grant letter
            </p>
            <VStack className="mt-4">
              <Box className="w-60">
                {!formik.values.grantSignature ? (
                  <label className="inline-flex items-center px-2 py-2 pr-20 text-sm text-center text-gray-500 bg-gray-100 rounded-md cursor-pointer peer">
                    <Input
                      type="file"
                      className="hidden"
                      id="OPP"
                      accept="image/png, image/jpeg"
                      onChange={async (e) => {
                        if (e?.target?.files?.[0]) {
                          const file = e.target.files[0];
                          const img = new Image();
                          img.src = URL.createObjectURL(file);
                          img.onload = async () => {
                            const width = img.width;
                            const height = img.height;
                            if (width > 240 || height > 120) {
                              toast(
                                "File height and width should be less than 120px and 240px",
                                {
                                  type: "error",
                                }
                              );
                              e.target.files = null;
                              e.target.value = "";
                            } else if (isValidImageFileUploaded(file, e)) {
                              const base64: string = (await convertToBase64(
                                file
                              )) as string;
                              formik.setFieldValue("grantSignature", base64);
                            }
                          };
                        }
                      }}
                    />
                    Upload a signature
                  </label>
                ) : (
                  <HStack className="relative flex">
                    <div
                      className="text-red-500 underline hover:cursor-pointer"
                      onClick={() => setImageDialog({ open: true })}
                    >
                      Authorised Signature
                    </div>
                    <label className="inline-flex items-center px-4 pb-1 text-sm text-center text-gray-500 rounded-md cursor-pointer peer">
                      <Input
                        type="file"
                        className="hidden"
                        id="OPP"
                        accept="image/png, image/jpeg"
                        onChange={async (e) => {
                          if (e?.target?.files?.[0]) {
                            const file = e.target.files[0];
                            const img = new Image();
                            img.src = URL.createObjectURL(file);
                            img.onload = async () => {
                              const width = img.width;
                              const height = img.height;
                              if (width > 240 || height > 120) {
                                toast(
                                  "File height and width should be less than 120px and 240px",
                                  {
                                    type: "error",
                                  }
                                );
                                e.target.files = null;
                                e.target.value = "";
                              } else if (isValidImageFileUploaded(file, e)) {
                                const base64: string = (await convertToBase64(
                                  file
                                )) as string;
                                formik.setFieldValue("grantSignature", base64);
                              }
                            };
                          }
                        }}
                      />
                      <Icon icon="ic:baseline-file-upload" height={24} />
                    </label>
                  </HStack>
                )}
              </Box>
            </VStack>
            <Dialog
              open={imageDialog.open}
              onClose={() => setImageDialog({ open: false })}
              maxWidth="lg"
            >
              {
                <img
                  className="image"
                  src={`data:image/jpeg;base64, ${formik.values.grantSignature}`}
                  onClick={handleShowDialog}
                  alt="no image"
                />
              }
            </Dialog>
          </HStack>
        </VStack>
      </Box>

      {!isUserEsopViewer && (
        <HStack className="justify-between mt-12">
          <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            disabled={isError}
            onClick={(e) => {
              handleSubmit();
            }}
            type="button"
          >
            Save
          </ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}

export default GrantLetterSettings;
